/* FEEDBACK MODAL */

// open feedback modal
function feedbackModal() {
    $("#feedback-modal").modal();
    setTimeout(function () {
        $("#feedback-modal").find("textarea").focus();
        // Load list of courses to selector and switch it to visible, if at least one course is there:
        $.ajax({
            type: "GET",
            url: "api/v5/courses",
            success: function (data) {
                obj = $.parseJSON(data);
                console.log(obj);
                if (obj.length > 0) {
                    $("#feedback-modal #courseSelection").show();
                    var fillString = '<option value="">' + i18n.gettext("Please select a course if you have a course-related issue or question:") + '</option>';
                    $.each(obj, function (index, value) {
                        fillString += '<option value="' + value.id + '">' + value.name + '</option>';
                    });
                    $("#feedback-modal #courseSelection").html(fillString);
                } else {
                    $("#feedback-modal #courseSelection").hide();
                    debug("No course found.");
                }
            }
        });
    }, 0);
    // loadNewsToFeedbackModal();
}

function cardFeedbackModal() {
    $("#card-feedback-modal").modal();
    setTimeout(function () {
        $("#card-feedback-modal").find("textarea").focus();
    }, 0);
}

function copyrightFeedbackModal() {
    $("#copyright-feedback-modal").modal();
    if ($("#cardId").length > 0) {
        $("#copyright-feedback-text-card").show();
        $("#copyright-feedback-text-cardset").hide();
    } else {
        $("#copyright-feedback-text-card").hide();
        $("#copyright-feedback-text-cardset").show();
    }
    setTimeout(function () {
        $("#copyright-feedback-modal").find("textarea").focus();
    }, 0);
}

// send feedback
function feedbackModalAction() {
    flashModal(i18n.gettext('Sending feedback...'));
    var topic;
    var courseId;
    if ($('#feedbackTopicSelection').length > 0) {
        topic = $('#feedbackTopicSelection').val();
    }
    if ($("#feedback-modal #courseSelection").length > 0) {
        courseId = $("#feedback-modal #courseSelection").val();
    }
    // var currentUrl = i18n.gettext("Sent from:") + " <a href=\"" + document.URL + "\">" + document.URL + "</a><br><br>";
    var message = encodeURIComponent($('#feedback-modal').find("textarea").val());
    $.ajax({
        type: "POST",
        url: "feedback",
        data: "topic=" + topic + "&courseId=" + courseId + "&message=" + message,
        success: function (data) {
            $('#feedback-modal').find("textarea").val('').css('height', 'auto');
            flashModal(data);
        }
    });
}

function pollModalAction() {
    debug("Sending feedback...");
    flashModal(i18n.gettext('Sending feedback...'));
    $.ajax({
        type: "POST",
        url: "poll",
        data: "feedback=" + encodeURIComponent($('#poll-modal').find("textarea").val()),
        success: function (data) {
            $('#poll-modal').find("textarea").val('').css('height', 'auto');
            flashModal(data);
        }
    });
}

function setPollCanceled() {
    $.ajax({
        type: "POST",
        url: "poll",
        data: "cancel=1"
    });
}

function setPollFinished() {
    $.ajax({
        type: "POST",
        url: "poll",
        data: "finish=1"
    });
}

// send feedback
function cardFeedbackModalAction() {
    flashModal(i18n.gettext('Sending feedback...'));
    var cardId = $("#cardId").val();
    $.ajax({
        type: "POST",
        url: "karte-" + cardId,
        data: "feedback=" + encodeURIComponent($('#card-feedback-modal').find("textarea").val()),
        success: function (data) {
            $('#card-feedback-modal').find("textarea").val('').css('height', 'auto');
            flashModal(data);
        }
    });
}

// send feedback
function copyrightFeedbackModalAction() {
    var text = $('#copyright-feedback-modal').find("textarea").val();
    if (text.trim() == '' || text.trim().length < 10) {
        $("#copyright-modal-warning-message").html("<div style=\"margin-top:10px;\">" + "Please enter a specific reason and your contact information!" + "</div>");
    } else {
        var boxId = $("#boxId").val();
        var cardId = 0;
        if ($("#cardId").length > 0) {
            cardId = $("#cardId").val();
        }
        flashModal(i18n.gettext('Sending complaint...'));
        $.ajax({
            type: "POST",
            url: "cardset-" + boxId,
            data: "cardId=" + cardId + "&copyrightComplaint=" + encodeURIComponent(text),
            success: function (data) {
                $('#copyright-feedback-modal').find("textarea").val('').css('height', 'auto');
                flashModal(data);
            }
        });
    }
}
