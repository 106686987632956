function checkIfLocallySavedCardsPresent(boxId) {
    if (typeof boxId === 'undefined') {
        boxId = $("#boxId").val();
        debug("boxId is: " + boxId);
    } else {
        debug("No boxId found: " + boxId);
    }
    var value = window.localStorage.getItem("createCardsData_" + boxId);
    if (value != null) {
        // var dataObj = JSON.parse(value);
        //TODO Create editors and pre-fill with values from dataObj
        // debug("We will pre-fill editors for " + dataObj.length + " card-ids:");

        var nonce = getNonce();

        var module = "card";
        var cardContext = "&cardContext=creating";
        var reCreateMultiple = "&reCreateMultiple=" + value;

        $.ajax({
            // retry-sensitive
            type: "POST",
            url: "kartensatz-karten-" + boxId,
            data: "module=" + module + cardContext + reCreateMultiple + "&nonce=" + nonce,
            success: function (data) {
                processMultipleNewCardsData(data);
            }
        }); // end ajax

        /*
        for (card of dataObj) {
            debug(card.cardId);
          	var question = decodeURIComponent(unescape(card.question));
            debug("Question: " + question);
          	var answer = decodeURIComponent(unescape(card.answer));
            debug("Question: " + answer);
        }
        */

    } else {
        debug("Nothing stored.");
    }
}

function newCard(boxId, multipleCards, cardAmount) {

    $('#firstCardsetActions').hide();

    //TODO
    // Maximale Anzahl erreicht? Per AJAX testen.

    // noetige Info:
    // - Anzahl in diesem Kartensatz erstellte Karten, also count(questionKey) mit groupKey=...
    // - Maximal erlaubte Anzahl Karten pro Kartensatz, also numOfCardsPerBox

    var nonce = getNonce();

    $.ajax({
        type: "GET",
        url: "api/v5/createcard/" + boxId + "/" + cardAmount + "/" + nonce,
        success: function (data) {
//            try {

            var obj = JSON.parse(data.trim());
            // alert(obj.assignedCategoryKeys[0]);

            // create categories array
            // newCardCategories = [];
            // $.each(obj.categories, function(key,value) {
            // 	if ($.inArray(key,obj.assignedCategoryKeys) !== -1) {
            // 		newCardCategories.push([key,value,"selected"]);
            // 	} else {
            //     	newCardCategories.push([key,value,""]);
            //     }
            // });
            // debug("newCardCategories: "+newCardCategories.toString());
            // debug("assignedCategoryKeys: "+obj.assignedCategoryKeys);

            $("#numOfCreatedCards").val(parseInt(obj.numOfCreatedCards));
            $("#numOfCardsPerBox").val(parseInt(obj.numOfCardsPerBox));
            $("#proUser").val(obj.proUser);

//                debug("cardAmount: " + cardAmount);
//                debug("obj.numOfCreatedCards: " + parseInt(obj.numOfCreatedCards));
//                debug("obj.numOfCardsPerBox: " + parseInt(obj.numOfCardsPerBox));
//                debug("createdCards + cardAmount: " + (parseInt(obj.numOfCreatedCards) + parseInt(cardAmount)));
            if ((parseInt(obj.numOfCreatedCards) + parseInt(cardAmount)) > parseInt(obj.numOfCardsPerBox)) {

                if (obj.proUser) {
                    flashModal(i18n.gettext('Maximum number of cards per cardset reached.')
                            + ' '
                            + i18n.gettext('Cards in trash are counted as well.'));
                } else {
                    repeticoPromptModal(i18n.gettext('Maximum number of cards per cardset for FREE-users reached.')
                            + ' ' + i18n.gettext('Cards in trash are counted as well.')
                            + '<br/><br/>'
                            + sprintf(i18n.gettext('Create up to 2000 cards per box with %s!'), '<span style="font-weight:bold;">' + 'Repetico' + ' PRO</span>'));
                }

                $(".multiple-new-cards-container").slideUp(400, 'swing', function () {
                    $(".multiple-new-cards-container").html('');
                });

            } else {

                // - Falls nein, weitermachen.

//                    var cardIds = obj.cardIds;
//                    $("#idsOfNewCards").val(cardIds.join());

                if (multipleCards) {
                    $(".multiple-new-cards-container").show();
                } else {
                    $('.new-card').slideDown();
                }
                $(".js-multiple-new-cards-btn").addClass("active");
                $(".js-multiple-new-cards-btn").prop("disabled", true);
                $(".new-card-btn").addClass("active");
                $(".new-card-btn").prop("disabled", true);
                $(".js-edit-card-btn").addClass("active");
                $(".js-edit-card-btn").prop("disabled", true);

                $("#cardset-content .explanation").hide();
                // removeTinyMcesInside($(".new-card"));
                removeAllTinyMces(true);
                var newCardQuestionText = $(".new-card .question-text");
                newCardQuestionText.tinymce(editorConfig(newCardQuestionText));
                var newCardAnswerText = $(".new-card .answer-text");
                newCardAnswerText.tinymce(editorConfig(newCardAnswerText));
                $(".new-card .mc-option-text").each(function () {
                    $(this).tinymce(editorConfig($(this)));
                });

                if (multipleCards) {
                    equalizeCardTopTextHeight($(".multiple-new-cards-container").find(".card"));
                } else {
                    equalizeCardTopTextHeight($(".new-card .card"));
                }

                setTimeout(function () {
                    if (multipleCards) {
                        $(".multiple-new-cards-container .question-text:first").focus();
                    } else {
                        $(".new-card .question-text:first").focus();
                    }
                }, 250);

            } // end else

//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }

        } // end success

    }); // end ajax

}




$(window).load(function () { // $(document).ready doesn't work here, because equalizeCardTopTextHeight fn relies on rendered CSS!
    equalizeCardTopTextHeight($(".card:last"));
});

/* equalize height of the categories text and the multiple choice text, in case there are so many categories that it is more than one line */
function equalizeCardTopTextHeight(thisCard) {
    // debug('ECTTH');
    // multiple cards -> all cards have same categories, adjust all answers to first question
    if (thisCard.length > 1) {
        thisCard.each(function () {
            equalizeCardTopTextHeight($(this));
        });
    } else { // one card
        if (thisCard.find(".answer .card-top-text").outerHeight() !== thisCard.find(".question .card-top-text").outerHeight()) {
            // adjust height only for small layout
            if (thisCard.closest(".layout-control").hasClass("small")) {
                thisCard.find(".answer .card-top-text").css("height", thisCard.find(".question .card-top-text").outerHeight());
            } else { // auto heights for medium and wide layouts
                thisCard.find(".answer .card-top-text").css("height", "auto");
            }
        }
    }
}



/* CARD CHANGE LAYOUT small <-> medium <-> wide */
function wideLayoutBtnClick(el) {
    $(el).closest(".layout-control").addClass("wide");
    $(el).closest(".layout-control").removeClass("medium");
    if ($(el).closest(".new-card").length > 0) {
        setCookie("newCardLayout", "wide", 9999);
    } else if ($(el).closest(".view-card").length > 0) {
        $(".top-part .previous-card-btn").removeClass("medium");
        $(".top-part .next-card-btn").removeClass("medium");
        setCookie("viewCardLayout", "wide", 9999);
    } else if ($(el).closest(".learn-card").length > 0) {
        setCookie("learnCardLayout", "wide", 9999);
    }
}

function mediumLayoutBtnClick(el) {
    $(el).closest(".layout-control").addClass("medium");
    $(el).closest(".layout-control").removeClass("small");
    $(el).closest(".layout-control").removeClass("wide");
    if ($(el).closest(".new-card").length > 0) {
        setCookie("newCardLayout", "medium", 9999);
    } else if ($(el).closest(".view-card").length > 0) {
        $(".top-part .previous-card-btn").addClass("medium");
        $(".top-part .next-card-btn").addClass("medium");
        setCookie("viewCardLayout", "medium", 9999);
    } else if ($(el).closest(".learn-card").length > 0) {
        setCookie("learnCardLayout", "medium", 9999);
    }
    equalizeCardTopTextHeight($(el).closest(".card-type").find(".card"));
}

function smallLayoutBtnClick(el) {
    $(el).closest(".layout-control").addClass("small");
    $(el).closest(".layout-control").removeClass("medium");
    if ($(el).closest(".new-card").length > 0) {
        setCookie("newCardLayout", "small", 9999);
    } else if ($(el).closest(".view-card").length > 0) {
        $(".top-part .previous-card-btn").removeClass("medium");
        $(".top-part .next-card-btn").removeClass("medium");
        setCookie("viewCardLayout", "small", 9999);
    } else if ($(el).closest(".learn-card").length > 0) {
        setCookie("learnCardLayout", "small", 9999);
    }
    equalizeCardTopTextHeight($(el).closest(".card-type").find(".card"));
}



// click on question/answer background puts focus on editor, too, so whole area is clickable
function focusQuestionText(el) {
    try {
        $(el).siblings(".question").find(".question-text").focus();
    } catch (e) {
        debug("Setting focus on question failed.");
    }
}

function focusAnswerText(el) {
    try {
        $(el).siblings(".answer").find(".answer-text").focus();
    } catch (e) {
        debug("Setting focus on answer failed.");
    }
}

// close new card form
function cancelCreateCard(el, force) {
    force = typeof force !== 'undefined' ? force : false;
    if (force || confirm(i18n.gettext("Delete really?"))) {

        var boxId = $("#boxId").val();
        var cardIds = [];

        window.localStorage.removeItem("createCardsData_" + boxId);

        $(".multiple-new-cards-container .card").each(function () {
            cardIds.push($(this).data("card-id"));
        });

        debug("Now deleting cards of box " + boxId + " which are: " + cardIds);
        deleteMultipleCards(boxId, cardIds, false, !force);

        if ($(el).parents(".multiple-new-cards-container").length > 0) {
            $(".multiple-new-cards-container").slideUp(400, 'swing', function () {
                $(".multiple-new-cards-container").html('');
            });
        } else {
            $('.new-card').slideUp();
        }
        $(".js-multiple-new-cards-btn").removeClass("active");
        $(".js-multiple-new-cards-btn").prop("disabled", false);
        $(".js-multiple-new-cards-btn").removeAttr("disabled");
        $('.new-card-btn').removeClass("active");
        $('.new-card-btn').prop("disabled", false);
        $('.new-card-btn').removeAttr("disabled");
        $('.js-edit-card-btn').removeClass("active");
        $('.js-edit-card-btn').prop("disabled", false);
        $('.js-edit-card-btn').removeAttr("disabled");

        $("#cardset-content .explanation").show();

        removeAllTinyMces(true);
        // removeTinyMcesInside($(el).closest(".card-type"));

        if (!force && (($(".explanation").length > 0 && $("#cardset-cards-content .card-wrapper").length > 0) || $("#firstCardsetActions").length > 0)) {
            $(".explanation").remove();
            loadPageToCenter("kartensatz-karten-" + $("#boxId").val());
        }

    }

    // REMOVE OLD INPUTS!?

    // if multiple choice, remove all options and create a blank new one
    // if($(".mc-option-text").length) {
    // 	$(".mchoice-answer-container").remove();
    // 	$(".new-card").find(".answer").append(' \
    // 		<div class="mchoice-answer-container"> \
    // 			<span class="custom-checkbox"> <input type="checkbox" id="mchoice-checkbox-1" class="mchoice-checkbox"> <span \
    // 				class="box"><i class="fa fa-check"></i></span> \
    // 			</span> \
    // 			<div class="editor-new-card-answer input editable w100 mc-option-text" tabindex="41"></div> \
    // 		</div> \
    // 	');
    // 	var editorConfig = editorConfigNormal;
    // 	$(".mc-option-text").tinymce(editorConfig);
    // }
}

function getCurrentUnixTimestamp() {
    return Math.round((new Date()).getTime() / 1000);
}

// click on create button empties editors, resets height and focuses question editor again, so next question can be entered immediately
function createCardBtnClick(el) {
    reinitializeAllTinyMces();
    var thisCardType = $(el).closest(".card-type.new-card:visible");
    var timestamp = $(el).data("timestamp");
    if (timestamp == 0) {
        timestamp = getCurrentUnixTimestamp();
        $(el).data("timestamp", timestamp);
    }
    createCard(thisCardType, timestamp);
}


function getContentOfOpenCardEditors(el) {
    var thisCardType = $(".multiple-new-cards-container").find(".card-type.new-card:visible");
    var dataObj = [];
    $(thisCardType.find(".card").get().reverse()).each(function () {
        if (!checkIfCardIsEmpty($(this))) {
            var card = new Object();
            card.question = escape(encodeURIComponent($(this).find(".question-text").html().trim()));
            card.answer = escape(encodeURIComponent($(this).find(".answer-text").html().trim()));
            if ($(this).find(".answer").hasClass("mc")) {
                card.mChoiceData = [];
                $(this).find(".choice-container").each(function (i) {
                    var mChoiceAnswerObj = Object();
                    var mcChecked = $(this).find(".mchoice-checkbox").prop('checked');
                    mChoiceAnswerObj.correct = mcChecked ? 1 : 0;
                    mChoiceAnswerObj.mChoiceAnswer = escape(encodeURIComponent($(this).find(".mc-option-text").html().trim()));
                    card.mChoiceData.push(mChoiceAnswerObj);
                });
            } else {
                card.mChoiceData = null;
            }

            card.categories = $(this).data('categories');
            card.cardId = $(this).data('card-id');
            card.boxId = $(this).data('box-id');
            dataObj.push(card);
        }
    }); // end card each
    return dataObj;
}


function checkIfCardIsEmpty(thisCard) {
    var empty = true;
    var selection = thisCard.find(".mce-content-body");
    // var selection = thisCard.find(".question-text, .answer-text, .mc-option-text");
    // find .question-text and .answer-text and .mc-option-text instead ?

    if (selection.length == 0) {
        debug("Did not find a content body inside of " + thisCard);
        // var path = getDomPath(thisCard);
        // debug(path.join(' > '));
    }
    selection.each(function () {
        var content = $(this).html().trim();
        // onsole.log("Card content is: #" + content + "#");
        if (
                !content.match('^<div><span style="font-size: [0-9]{1,2}px;">&#65279;<\/span><\/div>$') &&
                !content.match('^<div><span style="font-size: [0-9]{1,2}px;">&#65279;<\/span>&#65279;<\/div>$') &&
                !content.match('^<div>&#65279;</div>$') &&
                content !== '<div>&#160;</div>' &&
                content !== '') { // tinymce root block is set to div!
            // debug("Not empty.");
            empty = false;
        } else {
            // debug("Is empty.");
        }
    });
    return empty;
}

function backslashify(string) {
    return string.replace("\\", "\\\\\\\\");
}

function createCardError() {
    flashModal(i18n.gettext("Saving of card(s) failed.") + " " + i18n.gettext("Please try again!"), 3000);
    hideAjaxLoader();
    if ($(".multiple-new-cards-container").length > 0) {
        $(".multiple-new-cards-container").fadeTo(400, 1.0);
    }
    if ($(".new-card").length > 0) {
        $(".new-card").fadeTo(400, 1.0);
    }
}

function makeCreateCardDataObj() {
    var dataObj = [];
}

function createCard(thisCardType, timestamp) {

    debug("createCard");

    // Falls nicht (mehr) eingeloggt: Login-Modal anzeigen, Login verarbeiten und dann nochmals versuchen.

    // number of cards in list before saving completed:
    var numOfCardsBefore = $(".card.one-line").length;

    var multipleCards = (thisCardType.closest(".multiple-new-cards-container").length > 0) ? true : false;

    var boxId = thisCardType.data("box-id");

    var orderIndex = null;
    if (thisCardType.find(".select-position").length > 0) {
        orderIndex = thisCardType.find(".select-position select").val();
    }

    // var proUser = $("#proUser").val();

    var dataObj = [];
    var deleteCards = [];
    var temporaryIds = [];
    // var timestamps = [];
    // var cardStrings = [];
    var currentTemporaryId = new Date().getTime();

    // turns true if single card is empty or all of multiple new cards are empty
    // var allEmpty = false;

    // get keywords
    var keywords = "";
    if ($(".keywords-value").length > 0) {
        keywords = escape(encodeURIComponent($(".keywords-value").val().trim()));
    }
    debug("***** Card keywords are: " + keywords);

    var preselectedCategories = [];

    var foundUnuploadedPicture = false;

    debug("Iterating through cards...");

    var creatingCardCanceled = false;

    // the order of multiple cards needs to be reversed, because they are positioned one after the other at the same position. This way the final order in the list is as expected.
    $(thisCardType.find(".card").get().reverse()).each(function () {

        if (creatingCardCanceled) {
            return;
        }
        debug("Next card.");

        // don't save card if it's completely empty
        if (!checkIfCardIsEmpty($(this))) {

            var card = new Object();

            // get card content (question,answer,mchoice) and meta data (categories)
            card.question = escape(encodeURIComponent($(this).find(".question-text").html().trim()));
            card.answer = escape(encodeURIComponent($(this).find(".answer-text").html().trim()));

            if ($(this).find(".question-text").html().indexOf('src="blob:') > -1 || $(this).find(".answer-text").html().indexOf('src="blob:') > -1) {
                foundUnuploadedPicture = true;
            }

            // var mchoiceData = "";
            if ($(this).find(".answer").hasClass("mc")) {
                card.mChoiceData = [];
                $(this).find(".choice-container").each(function (i) {
                    var mChoiceAnswerObj = Object();
                    var mcChecked = $(this).find(".mchoice-checkbox").prop('checked');
                    mChoiceAnswerObj.correct = mcChecked ? 1 : 0;
                    mChoiceAnswerObj.mChoiceAnswer = escape(encodeURIComponent($(this).find(".mc-option-text").html().trim()));
                    if ($(this).find(".mc-option-text").html().indexOf('src="blob:') > -1) {
                        foundUnuploadedPicture = true;
                    }
                    card.mChoiceData.push(mChoiceAnswerObj);
                    // mchoiceData += "&mchoiceCorrect_" + (i + 1) + "=" + correct;
                });
                $(this).find(".mc-option-text").each(function (i) {
                    // mchoiceData += "&mchoice_" + (i + 1) + "=" + encodeURIComponent($(this).html());
                });
            } else {
                card.mChoiceData = null;
            }

            card.categories = $(this).data('categories');

            if ($("html").data("theme") == "avoxa") {
                if (card.categories.length == 0) {
                    generalPurposeModal("Du musst f&uuml;r jede Karte mindestens eine Kategorie ausw&auml;hlen.", "Kategorisierung fehlt");
                    creatingCardCanceled = true;
                    return;
                }
            }

            setCategoriesCookie(boxId, card.categories);
            preselectedCategories = card.categories;

            card.cardId = $(this).data('card-id');
            card.boxId = $(this).data('box-id');

            debug("*** card has creating timestamp: " + timestamp);
            card.timestamp = timestamp;

            // create card element (for instant user feedback)
            var origQuestion = $(this).find(".question-text").html().trim();
            var div = document.createElement("div");
            div.innerHTML = origQuestion;
            var textContentOfQuestion = div.textContent || div.innerText || "";

            var origAnswer = $(this).find(".answer-text").html().trim();
            div = document.createElement("div");
            div.innerHTML = origAnswer;
            var textContentOfAnswer = div.textContent || div.innerText || "";

            var temporaryIdOfNewCard = "tmpcard-" + currentTemporaryId;
            temporaryIds.push(temporaryIdOfNewCard);
            var orderby = $("#orderby").val();

            debug("Card keywords are: " + keywords);
            card.keywords = keywords;

            dataObj.push(card);

        } // end if card not empty
        else {
            debug("The card is apparently empty?");
            if (multipleCards) {
                if ($(thisCardType).find(".card").length !== 1) {
                    // $(this).remove();
                }
                if ($(thisCardType).find(".card").length === 1) {
                    allEmpty = true;
                }
            } else {
                allEmpty = true;
            }
            deleteCards.push($(this).data('card-id'));
        }

        currentTemporaryId++;

    }); // end card each

    if (!creatingCardCanceled) {
        if (dataObj.length === 0) {
            flashModal(i18n.gettext('You cannot create an empty card. Please enter some content.'), 3000, "error");
        } else {
            if (foundUnuploadedPicture) {
                alert(i18n.gettext("At least one file is not yet finished uploading. Please try again when the file is uploaded."));
                //            if (d == true) {
                //                finishSavingCards(dataObj, multipleCards, boxId, orderIndex, deleteCards, numOfCardsBefore, preselectedCategories);
                //            }
            } else {
                finishSavingCards(dataObj, multipleCards, boxId, orderIndex, deleteCards, numOfCardsBefore, preselectedCategories);
            }
        }	// end not allEmpty
    }
} // end fn createCard

function finishSavingCards(dataObj, multipleCards, boxId, orderIndex, deleteCards, numOfCardsBefore, preselectedCategories) {
    flashModal('<div style="text-align:center;">' + i18n.gettext('Saving...') + '<br><br><img src="' + $("html").data("approot_images") + 'ajax-loader-2.gif">' + '</div>', 50000);

    if (multipleCards) {
        // showAjaxLoader(".multiple-new-cards-container", 20);
        $(".multiple-new-cards-container").fadeTo(400, 0.5);
    } else {
        // showAjaxLoader(".new-card", 20);
        $(".new-card").fadeTo(400, 0.5);
    }

    var nonce = getNonce();
    dataObj = dataObj.reverse();
    var createCardsData = JSON.stringify(dataObj);

    //TODO Save in local storage of browser - to be able to restore it in case of saving failure.
    window.localStorage.setItem("createCardsData_" + boxId, createCardsData);

    debug(createCardsData);

    $.ajax({
        // retry-sensitive (not necessarily)
        type: "POST",
        url: "kartensatz-karten-" + boxId,
        data: "saveMultipleCards=" + createCardsData + "&orderIndex=" + orderIndex + "&deleteCardsWhileCreating=" + deleteCards + "&nonce=" + nonce,
        success: function (data) {

            closeModal();

            var onSaveCreateNew = $(".onSaveCreateNew").is(":checked");
            debug("onSaveCreateNew is: " + onSaveCreateNew);

            var obj = JSON.parse(data.trim());

            if (obj.loggedIn == false) {

                loginModal('createCardByKeypress');

            } else {

                var cardIds = obj.cardIds;
                var cards = obj.cards;

                if (cardIds.length < 1 || cardIds.length < dataObj.length) {

                    createCardError();

                } else if (numOfCardsBefore === 0) {

                    window.localStorage.removeItem("createCardsData_" + boxId);

                    if (cardIds.length > 1) {
                        // shortInfo("Karten erstellt.", 2000);
                        flashModal(cardIds.length + " " + i18n.gettext("cards saved."));
                    } else if (cardIds.length === 1) {
                        // shortInfo("Karte erstellt.", 2000);
                        flashModal(i18n.gettext("Card saved."));
                    }

                    if (onSaveCreateNew) {
                        setCookie("onSaveCreateNew", true, 9999);
                        loadPageToCenter("kartensatz-karten-" + boxId, function () {
                            createMultipleCards(cardIds.length, preselectedCategories);
                        });
                    } else {
                        setCookie("onSaveCreateNew", false);
                        if ($("html").hasClass("page-kartensatz") || cardIds.length > 1) {
                            loadPageToCenter("kartensatz-karten-" + boxId);
                        } else {
                            loadPageToCenter("card-" + cardIds[0]);
                        }
                    }

                    return;

                } else {

                    window.localStorage.removeItem("createCardsData_" + boxId);

                    // only remove editors and contents of editors if saving was really successful!

                    loadCardsContent(true);

                    if ($(".explanation").length > 0 && $(".card-wrapper").length > 0) {

                        $(".explanation").remove();
                        $(".js-cardset-cards-controls").removeClass("hidden");
                        // loadPageToCenter("kartensatz-" + $("#boxId").val());

                    }

                    if (multipleCards) {
                        $(".multiple-new-cards-container").fadeTo(400, 1.0);
                    } else {
                        $(".new-card").fadeTo(400, 1.0);
                    }

                    if (cardIds.length > 1) {
                        // shortInfo("Karten erstellt.", 2000);
                        flashModal(cardIds.length + " " + i18n.gettext("cards saved."));
                    } else if (cardIds.length === 1) {
                        // shortInfo("Karte erstellt.", 2000);
                        flashModal(i18n.gettext("Card saved."));
                    }

                    removeAllTinyMces(true);
                    $(".multiple-new-cards-container").html('');

                    if ($(".js-order-by").val() === "fixedOrder" && $("html").data("prouser") === "1") {
                        try {
                            if ($('.sortable').length) {
                                $('.sortable').sortable('refresh');
                                // Default orderIndex for new cards is maximum available index, which is chosen on server side.

                            } else { // empty card set! just created the first card!
                                $(".sortable").sortable(
                                        {forcePlaceholderSize: true}
                                );
                            }
                        } catch (e) {

                        }
                    }

                    if (obj.cardsLeftToCreate < 1) {
                        flashModal("Limit der Anzahl Karten in diesem Kartensatz erreicht!");
                    }

                    $(".multiple-new-cards-container").slideUp(400, 'swing', function () {
                        $(".multiple-new-cards-container").html('');

                        if (onSaveCreateNew) {
                            setCookie("onSaveCreateNew", true, 9999);
                            createMultipleCards(cardIds.length, preselectedCategories);
                        } else {
                            setCookie("onSaveCreateNew", false);
                        }
                    });

                    // enable card creation links
                    $(".js-multiple-new-cards-btn").removeClass("active");
                    $(".js-multiple-new-cards-btn").prop("disabled", false);
                    $(".js-multiple-new-cards-btn").removeAttr("disabled");
                    $(".new-card-btn").removeClass("active");
                    $(".new-card-btn").prop("disabled", false);
                    $(".new-card-btn").removeAttr("disabled");
                    $(".js-edit-card-btn").removeClass("active");
                    $(".js-edit-card-btn").prop("disabled", false);
                    $(".js-edit-card-btn").removeAttr("disabled");

                    // refresh learn mode info
                    refreshLearnButtonInfos(boxId);

                    refreshActivityBarInit(true);

                }

            }

        },
        error: function (xhr, status, text) {

            if (status == 'timeout' || status == 'error') {
                this.tryCount++;
                if (this.tryCount <= this.retryLimit) {
                    debug("Speichern: Versuch " + this.tryCount);
                    $.ajax(this);
                    // shortInfo(i18n.gettext('Wiederholung der Server-Anfrage...'));
                } else {
                    debug("Versuch " + this.tryCount + " von " + this.retryLimit);
                    createCardError();
                }
                return;
            } else {
                createCardError();
            }
        },
        complete: function (data) {}
    }); // end ajax post cards
}

function reinitCardIndexes() {
    var i;
    $(".cardnumber").each(function (index) {
        i = index + 1;
        $(this).text(i);
    });
}


function refreshLearnButtonInfos(boxId) {
    $.get("kartensatz-karten-" + boxId + "?learnmodeInfo=1", function (data) {
        refreshLearnplanInfos(data);
    });
}


function switchToTypeOfCard(el, type) {

}

function switchToUncoverAnswers(el) {

}

// MULTIPLE CHOICE
function switchToMultipleChoiceAnswer(el) {

    var thisAnswer = $(el).closest(".answer");
    thisAnswer.addClass("mc");
    if (thisAnswer.find(".mc-option-list").length == 0) {
        thisAnswer.find(".js-add-mchoice-option-btn").before('<ul class="mc-option-list"></ul>');
        var boxId = thisAnswer.closest(".card-type").data("box-id");
        var cardId = thisAnswer.closest(".card").data("card-id");
        var mcOptionsCount = ($.cookie('mcOptionsCount-' + boxId) == undefined) ? 3 : $.cookie('mcOptionsCount-' + boxId);
        for (i = 0; i < mcOptionsCount; i++) {
            thisAnswer.find(".mc-option-list").append(' \
		        					<li> \
		        						<div class="choice-container"> \
		        							<div class="custom-checkbox"> \
		        								<input name="cardcheckbox' + cardId + '" type="checkbox" class="mchoice-checkbox"> \
		        								<!-- <span class="box"> \
		        									<i class="fa fa-check"></i> \
	        									</span> --> \
		        							</div> \
		        							<span class="option-label js-editor-label">' + i18n.gettext('Answer') + '</span> \
		        							<div class="mc-option-text" tabindex="40"></div> \
										<button class="remove-mchoice-option-btn" onClick="removeMultipleChoiceOption(this)"> \
											<i class="fa fa-minus-square-o"></i> \
										</button> \
		        						</div> \
	        						</li> \
		        			');
        }
        thisAnswer.find(".mc-option-text:first").addClass('init-focus');
        thisAnswer.find(".mc-option-text").each(function () {
            $(this).tinymce(editorConfig($(this)));
        });
    } // end if .mc-option-list doesn't exist yet

    thisAnswer.find(".answer-label").html(i18n.gettext("Explanation") + " (" + i18n.gettext("optional") + ")");
    $(el).addClass("active").attr("disabled", "disabled");
    $(el).siblings(".normal-question-btn").removeClass("active").removeAttr("disabled");

    $.ajax({
        type: "GET",
        url: "confsaver",
        data: "getProUserInfo=1",
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());
            if (!obj.proUser) {
                repeticoPromptModal(
                        // i18n.gettext('Creating of multiple choice cards not possible as FREE-User.') +
                        // '<br/><br/>' +
                        sprintf(i18n.gettext('Create multiple choice cards now with %s!'), '<span style="font-weight:bold;">' + 'Repetico' + ' PRO</span>'));
                switchToNormalAnswer(el);
                $(".multiple-choice-btn").removeClass('active');
                $(".multiple-choice-btn").attr("disabled", false);
                $(".multiple-choice-btn").removeAttr("disabled");
                $(".normal-question-btn").addClass('active');
                $(".normal-question-btn").attr("disabled", true);
                $(".normal-question-btn").removeAttr("disabled");
            } else {
                // set cookie for questionType so next new card is also of that type
                if ($(el).closest(".new-card").length > 0) {
                    setCookie("newCardQuestionType", "mchoice", 9999);
                }
            }
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }

        }

    }); // end ajax call

} // end multipleChoiceAnswer



function switchToNormalAnswer(el) {
    var thisAnswer = $(el).closest(".answer");
    thisAnswer.removeClass("mc");
    thisAnswer.find(".answer-label").text(i18n.gettext("Answer"));
    $(el).addClass("active").attr("disabled", "disabled");
    $(el).siblings(".multiple-choice-btn").removeClass("active").removeAttr("disabled");
    // set cookie for questionType so next new card is also of that type
    if ($(el).closest(".new-card").length > 0) {
        setCookie("newCardQuestionType", "normal", 9999);
    }
}



// add multiple choice option
function addMultipleChoiceOption(el) {
    $.ajax({
        type: "GET",
        url: "confsaver",
        data: "getProUserInfo=1",
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());
            if (!obj.proUser) {
                repeticoPromptModal(i18n.gettext('Adding of answers to multiple choice cards not possible as FREE-user.')
                        + '<br/><br/>'
                        + sprintf(i18n.gettext('Create multiple choice cards now with %s!'), '<span style="font-weight:bold;">' + 'Repetico' + ' PRO</span>'));
            } else {
                var thisAnswer = $(el).closest(".answer");
                var cardId = thisAnswer.closest(".card").data("card-id");
                thisAnswer.find(".mc-option-list").append(' \
                        <li> \
                            <div class="choice-container"> \
                                <div class="custom-checkbox"> \
                                    <input name="cardcheckbox' + cardId + '" type="checkbox" class="mchoice-checkbox"> \
                                    <!-- <span class="box"> \
                                        <i class="fa fa-check"></i> \
                                    </span> --> \
                                </div> \
                                <span class="option-label js-editor-label">' + i18n.gettext('Answer') + '</span> \
                                <div class="mc-option-text init-focus" tabindex="40"></div> \
                                <button class="remove-mchoice-option-btn" onClick="removeMultipleChoiceOption(this)"> \
                                    <i class="fa fa-minus-square-o"></i> \
                                </button> \
                            </div> \
                        </li> \
                    ');
                var lastMcOptionText = thisAnswer.find(".mc-option-text:last");
                lastMcOptionText.tinymce(editorConfig(lastMcOptionText));

                // update multiple-choice-option count cookie
                var boxId = $(el).closest(".card-type").data("box-id");
                var mcOptionsCount = $(el).closest(".answer").find(".mc-option-text").length;
                setCookie('mcOptionsCount-' + boxId, mcOptionsCount, 9999);
            }
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }

        }
    });
}



// remove multiple choice option
function removeMultipleChoiceOption(el) {
    // update multiple-choice-option count cookie
    var boxId = $(el).closest(".card-type").data("box-id");
    var mcOptionsCount = $(el).closest(".answer").find(".mc-option-text").length - 1;
    setCookie('mcOptionsCount-' + boxId, mcOptionsCount, 9999);

    $(el).siblings(".mc-option-text").tinymce().remove();
    $(el).closest("li").remove();
}


function switchCardText() {
    var boxId = $('#boxId').val();
    var cardId = $('#cardId').val();
    $('#center').fadeTo(200, 0.5);
    $.get('karte-' + cardId + '?flipSides=1', function (data) {
        loadPageToCenter('karte-' + cardId);
    });
}

function deactivateCardFlipping() {
    var cardId = $('#cardId').val();
    $('#center').fadeTo(200, 0.5);
    $.get('karte-' + cardId + '?unselectForLearning=1', function (data) {
        loadPageToCenter('karte-' + cardId);
    });
}

function activateCardFlipping() {
    var boxId = $('#boxId').val();
    var cardId = $('#cardId').val();
    $('#center').fadeTo(200, 0.5);
    $.get('karte-' + cardId + '?selectForLearning=1', function (data) {
        loadPageToCenter('karte-' + cardId);
    });
}

function resetCardStats() {
    var cardId = $('#cardId').val();
    if (confirm(i18n.gettext("Really reset all statistics and the study level of this card?"))) {
        $('#center').fadeTo(200, 0.5);
        $.get('karte-' + cardId + '?resetStats=1', function (data) {
            loadPageToCenter('karte-' + cardId);
        });
    }
}

function deactivateCardLearning(el) {
    var cardId = $(el).data('cardid');
    $.get('karte-' + cardId + '?unselectForLearning=1', function (data) {
        // loadPageToCenter('karte-' + cardId);
        $(".js-deactivate-card-btn").hide();
        $(".js-activate-card-btn").fadeIn(200);
        $(".question-container").addClass("deactivated");
    });
}

function activateCardLearning(el) {
    var cardId = $(el).data('cardid');
    $.get('karte-' + cardId + '?selectForLearning=1', function (data) {
        // loadPageToCenter('karte-' + cardId);
        $(".js-activate-card-btn").hide();
        $(".js-deactivate-card-btn").fadeIn(200);
        $(".question-container").removeClass("deactivated");
    });
}

function setCardTimestamp(el) {
    if ($(el).closest(".card").length > 0) {
        debug("Setze timestamp fuer Karte " + $(el).closest(".card").data("card-id"));
        $(el).closest(".card").data("timestamp", getCurrentUnixTimestamp());
    }
}

function tellIfCardLimitOfKeywordsReached() {
    var limit = 10;
    var keywordsString = $(".question-container .edit-mode .keywords-edit .keywords-value").val().trim();
    var keywords = keywordsString.split(",");
    if (keywords.length == limit) {
        flashModal(i18n.gettext("Limit reached - you can have a maximum number of 10 keywords per card."));
    }
}

// edit card button
function editCard(el) {

    //hj('trigger', 'editCard');

    $(".question-container .keywords-headline").show();
    $(".question-container .keywords-edit").show();
    $(".question-container .keywords-show").hide();

    var boxId = $("#boxId").val();

    $(".card-tts-button").hide();

    // lade den Original-Quelltext der Karte aus den dafür vorgesehenen Tags

    // id: questionUnformatted
    if ($("#questionUnformatted").length > 0) {
        var questionTextUnformatted = $("#questionUnformatted").html();
        $(".question-container").find(".card-type").find(".question-text").html(questionTextUnformatted);
    }

    // id: answerUnformatted
    if ($("#answerUnformatted").length > 0) {
        var answerTextUnformatted = $("#answerUnformatted").html();
        $(".question-container").find(".card-type").find(".answer-text").html(answerTextUnformatted);
    }

    // class: js-mchoiceText-unformatted
    var numOfMultipleChoiceAnswers = 0;
    $(".question-container .card-type .mc-option-text").each(function (i) {
        numOfMultipleChoiceAnswers++;
        if ($(this).next().hasClass("js-mchoiceText-unformatted")) {
            var mChoiceTextUnformatted = $(this).next().html();
            $(this).html(mChoiceTextUnformatted);
        }
    });

    $(".js-multiple-new-cards-btn").addClass("active").attr("disabled", "disabled");
    $(".new-card-btn").addClass("active").attr("disabled", "disabled");

    $(el).addClass("active").attr("disabled", "disabled");
    $(".js-save-card-btn").show();
    var cardType = $(".question-container").find(".card-type");
    cardType.addClass("edit-mode");

    var enterKeywordsString = i18n.gettext("Enter keywords (separate by enter or comma)");
    if ($(".keywords-value").length > 0) {
        $(".question-container .edit-mode .keywords-edit .keywords-value").tagsInput({
            'autocomplete_url': 'keywords',
            'autocomplete': {appendTo: ".question-container .edit-mode .keywords-edit .keyword-suggestions"},
            'defaultText': enterKeywordsString,
            'height': '40px',
            'width': '100%',
            'onAddTag': tellIfCardLimitOfKeywordsReached
        });

        $("#keywords-value_tag").on('focusout', function () {
            debug("*** Triggering enter.");
            triggerEnter("#keywords-value_tag");
        });

        $(".ui-autocomplete-input").css("width", "340px");
    }

    // create answer-text if it doesn't exist (empty)
    if (!cardType.find(".answer-text").length > 0) {
        cardType.find(".answer").append('\
			<div class="answer-text input editable w100 " tabindex="40"> \
		');
    }

    // add labels
    cardType.find(".question-text").before('<span class="question-label js-editor-label">' + i18n.gettext('Question') + '</span>');
    cardType.find(".answer-text").before('<span class="answer-label js-editor-label"></span>');
    if (numOfMultipleChoiceAnswers > 0) {
        cardType.find(".answer-label").html(i18n.gettext('Explanation (optional)'));
    } else {
        cardType.find(".answer-label").text(i18n.gettext('Answer'));
    }
    cardType.find(".mc-option-text").each(function () {
        $(el).before('<span class="option-label js-editor-label">' + i18n.gettext('Answer') + '</span>');
    });

    // init tinymces
    removeAllTinyMces();
    var questionText = cardType.find(".question-text");
    questionText.tinymce(editorConfig(questionText));
    var answerText = cardType.find(".answer-text");
    answerText.tinymce(editorConfig(answerText));
    cardType.find(".mc-option-text").each(function () {
        $(this).tinymce(editorConfig($(this)));
    });

    cardType.find(".choice-container input").removeAttr("disabled");

    // LEARNING
    if (cardType.hasClass("learn-card")) {

        $(".answer").removeClass("wrong");
        $(".answer").removeClass("correct");

        // remove user answer
        $(".my-answer").remove();

        // move correct answer content to answer and remove container
        $(".correct-answer .answer-label").appendTo(".answer");
        $(".correct-answer .answer-text").appendTo(".answer");
        $(".correct-answer").remove();

        // deactivate shortcuts when editing cards while learning
        $(document).off('keyup.learn-shortcuts');

        // save user multiple choice selection to localStorage, so it can be reset later
        var mcOptionSelection = new Array();
        cardType.find(".mchoice-checkbox").each(function () {
            mcOptionSelection.push($(el).prop("checked") ? true : false);
        });

        try {
            localStorage["mcOptionSelection"] = JSON.stringify(mcOptionSelection);
        } catch (e) {
            debug("Error when writing localStorage");
        }

        // check/uncheck correct/wrong checkboxes
        $.getJSON("mchoice_evaluator?id=" + $('#cardId').val(), function (data) {
            // debug(data);
            $.each(data, function (key, val) {
                cardType.find(".mchoice-checkbox.mchoice-checkbox-" + key).prop("checked", val);
            });
        });
    }
    equalizeCardTopTextHeight(cardType.find(".card"));
}

function setCategoriesCookie(boxId, categories) {
    setCookie("category_preselection_" + boxId, categories, 9999);
}

function createCardByKeypress() {
    var thisCard = $(".card-type.new-card:visible");
    var timestamp = $(".create-card-btn").data("timestamp");
    if (timestamp == 0) {
        timestamp = getCurrentUnixTimestamp();
        $(".create-card-btn").data("timestamp", timestamp);
    }
    createCard(thisCard, timestamp);
}

function keyPressAction(e) {
    // debug("Keycode: " + e.which);
    if ($(".card-type.new-card").length > 0 && $(".card-type.new-card").is(":visible")) {
        if (e.which === 83 && e.ctrlKey && !e.altKey) {
            e.preventDefault();
            e.stopImmediatePropagation();
            createCardByKeypress();
            return false;
        }
    } else if ($(".card-type.edit-mode").length > 0) {
        if (e.which === 83 && e.ctrlKey && !e.altKey) {
            e.preventDefault();
            e.stopImmediatePropagation();
            saveEditedCard();
            return false;
        }
    } else if ($("#discussion-text.input.editable").is(":visible")) {
        // do nothing
    } else if ($("html").hasClass("page-kartensatz-karte") && $(".next-card-btn").length > 0 && e.which === 39 && $(".modal.current").length == 0) {
        var href = $(".next-card-btn").attr('href');
        loadPageByAjax(href);
    } else if ($("html").hasClass("page-kartensatz-karte") && $(".previous-card-btn").length > 0 && e.which === 37 && $(".modal.current").length == 0) {
        var href = $(".previous-card-btn").attr('href');
        loadPageByAjax(href);
    }
}

function saveEditedCard() {

    // ??? Had a problem while saving MCHOICE card.
    reinitializeAllTinyMces();

    var cardId = $(".question-container").find(".card-type").data('cardId');
    var boxId = $(".question-container").find(".card-type").data('boxId');

    var foundUnuploadedPicture = false;

    var question = encodeURIComponent($(".question-container").find(".card-type").find(".question-text").html().trim());
    var answer = encodeURIComponent($(".question-container").find(".card-type").find(".answer-text").html().trim());

    if ($(".question-container").find(".card-type").find(".question-text").html().indexOf('src="blob:') > -1 || $(".question-container").find(".card-type").find(".answer-text").html().indexOf('src="blob:') > -1) {
        foundUnuploadedPicture = true;
    }

    var mchoiceData = "";
    if ($(".question-container").find(".card-type").find(".answer").hasClass("mc")) {
        $(".question-container").find(".card-type").find(".mchoice-checkbox").each(function (i) {
            // debug( i + ": " + $( this ).prop('checked') );
            var mcChecked = $(this).prop('checked');
            var correct = mcChecked ? 1 : 0;
            mchoiceData += "&mchoiceCorrect_" + (i + 1) + "=" + correct;
        });
        $(".question-container .card-type .mc-option-text").each(function (i) {
            mchoiceData += "&mchoice_" + (i + 1) + "=" + encodeURIComponent($(this).html().trim());
            if ($(this).html().indexOf('src="blob:') > -1) {
                foundUnuploadedPicture = true;
            }
        });
    }

    // get categories
    var categories = $(".question-container").find(".card-type").find(".card").data('categories');

    // get keywords
    var keywords = "";
    if ($(".keywords-value").length > 0) {
        keywords = escape(encodeURIComponent($(".question-container .edit-mode .keywords-edit .keywords-value").val().trim()));
    }

    // writing cookie in select-categories.js is enough!?
    // setCategoriesCookie(boxId,categories);

    if (foundUnuploadedPicture) {
        var d = confirm(i18n.gettext("At least one picture is not yet finished uploading. If you save the card now, the image could be lost. Continue?"));
        if (d == true) {
            finishSavingEditedCard(boxId, cardId, question, answer, mchoiceData, categories, keywords);
        }
    } else {
        finishSavingEditedCard(boxId, cardId, question, answer, mchoiceData, categories, keywords);
    }

} // end fn saveEditedCard

function finishSavingEditedCard(boxId, cardId, question, answer, mchoiceData, categories, keywords) {

    if ($(".question-container").find(".card-type").hasClass("view-card")) {
        $('#cardset-content').fadeTo(100, 0.5);
    } else if ($(".question-container").find(".card-type").hasClass("learn-card")) {
        $(".question-container").fadeTo(100, 0.5);
    }

    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + boxId,
        data: "cardId=" + cardId + "&question=" + question + "&answer=" + answer + mchoiceData + "&categories=" + categories + "&keywords=" + keywords,
        success: function (data) {
            cancelEditCard();
        }
    });

    // remove labels
    $(".js-editor-label").remove();
}

// cancel edit card
function cancelEditCardBtnClick() {
    if (confirm(i18n.gettext("Really cancel?"))) {
        cancelEditCard();
    }
}

function cancelEditCard() {
    $(".card-tts-button").show();
    // removeTinyMcesInside($(".question-container").find(".card-type"));
    removeAllTinyMces(true);
    $(".question-container").find(".card-type").removeClass("edit-mode");
    $(".question-container").find(".card-type").find(".choice-container input").prop("disabled", true);
    var cardId = $('#cardId').val();
    var container;
    var module;
    var cardContext;

    if ($(".question-container").find(".card-type").hasClass("view-card")) { // FLIPPING
        container = $("#cardset-content");
        module = "kartensatz_karte";
        cardContext = "";
    } else if ($(".question-container").find(".card-type").hasClass("learn-card")) { // LEARNING
        container = $(".question-container");
        module = "card";
        cardContext = "&cardContext=learning";
    }

    if ($(".question-container").find(".card-type").hasClass("view-card")) {
        $('#cardset-content').fadeTo(100, 0.5);
    } else if ($(".question-container").find(".card-type").hasClass("learn-card")) {
        $(".question-container").fadeTo(100, 0.5);
    }

    $.ajax({
        type: "POST",
        url: "karte-" + cardId,
        data: "module=" + module + cardContext,
        success: function (data) {
//            try {
            container.html(data);
            autosize($(".my-answer")); // for learn-card
//            } catch (e) {
//                debug('Html parse error');
//            }
            if ($(".question-container").find(".card-type").hasClass("view-card")) {
                $('#cardset-content').fadeTo(100, 1.0);
            } else if ($(".question-container").find(".card-type").hasClass("learn-card")) {
                $(".question-container").fadeTo(100, 1.0);
            }
            if ($(".question-text").hasClass('disableCopying')) {
                enableDocumentSelection(false);
            }
            equalizeCardTopTextHeight($(".question-container").find(".card"));
        }
    });

    if ($(".question-container").find(".card-type").hasClass("learn-card")) { // LEARNING

        // reactivate keyboard shortcuts when editing a card while learning
        // trigger shortcuts, on learning page, only!
        $(document).on('keyup.learn-shortcuts', '.page-lernen', function (e) {
            return keyPressActionLearning(e);
        });

        // reset checkboxes to user setting before edit
        try {
            var mcOptionSelectionString = localStorage["mcOptionSelection"];
        } catch (e) {
            debug("Error when reading localStorage");
        }
        var mcOptionSelection;
        if (mcOptionSelectionString != null && mcOptionSelectionString != "" && mcOptionSelectionString != "undefined") {
            mcOptionSelection = JSON.parse(mcOptionSelectionString);
        } else {
            mcOptionSelection = [];
        }
        $(".question-container").find(".card-type").find(".mchoice-checkbox").each(function (index) {
            if (index in mcOptionSelection) {
                $(this).prop("checked", mcOptionSelection[index]);
            }
        });

    } // end cardType if

    // remove labels
    $(".js-editor-label").remove();

    $(".js-multiple-new-cards-btn").removeClass("active").removeAttr("disabled");
    $('.new-card-btn').removeClass("active").removeAttr("disabled");
    $(".js-save-card-btn").hide();

} // end fn cancelEditCard



// Add/Remove card to/from Favorites
function favoriteCard(el) {
    var cardId = $(el).data("card-id");
    var datahelper;
    if ($(el).hasClass("favorite")) {
        dataHelper = "removeFavorite=";
    } else {
        dataHelper = "addFavorite=";
    }
    $.ajax({
        type: "POST",
        url: "confsaver",
        data: dataHelper + cardId,
        success: function (data) {
            $(".js-favorite-btn[data-card-id=" + cardId + "]").toggleClass("favorite");
        }
    });
    $(el).blur();
}

function addTagToCard(cardId, tagText) {
    var tagTextToSubmit = encodeURIComponent(tagText);
    $.ajax({
        type: "POST",
        url: "card-" + cardId,
        data: "addTag=" + tagTextToSubmit,
        success: function (data) {
            shortInfo(i18n.gettext("Added tag:") + " " + tagText)
        }
    });
}


// Deprecated: not needed any longer - it made evaluation of multiple choice in learning mode buggy!

function clickOnMultipleChoiceOption(e) {
    e.stopPropagation();
}

function toggleMultipleChoiceOptionLearning(el, checkAnswerImmediately, showCorrectAnswer) {
    if (!$(el).closest(".learn-card").hasClass("edit-mode")) {

        $(el).find("input").prop("checked", !$(el).find("input").prop("checked"));

        if (checkAnswerImmediately) {
            checkAnswer(showCorrectAnswer);
        }

//        // set checkbox to opposite of current state
//        // checkbox must have lower z-index or else this click function will trigger after checkbox has been clicked and reverse the change!
//        $(el).find("input").prop("checked", !$(el).find("input").prop("checked"));
//        $(el).toggleClass("checked");
//
//        // JSON.stringify all checked card ids. Put them into a specific storage for checked answers. Then fetch them back. Then only mark these answers as checked which are in the fetched values.
//        var checkedAnswersArray = [];
//        $(".question-container .card .answer .mc-option-list .choice-container").each(function(i,obj) {
//            if ($(obj).hasClass("checked")) {
//                checkedAnswersArray.push($(obj).data("choicenbr"));
//                $(obj).removeClass("checked");
//                $(el).find("input").prop("checked",false);
//            }
//        });
//        var checkedAnswersJson = JSON.stringify(checkedAnswersArray);
//        debug("Checked answers: " + checkedAnswersJson);
//        $(".js-check-answer-btn").data("checkedAnswers", checkedAnswersJson);
//
//        $(".question-container .card .answer .mc-option-list .choice-container").each(function(i,obj) {
//            var choiceNbr = $(obj).data("choicenbr");
//            if (checkedAnswersArray.includes(choiceNbr)) {
//                $(obj).addClass("checked");
//                $(el).find("input").prop("checked",true);
//            }
//        });
    }
}



function removeCardFromMultipleNewCards(el) {
    // explicitly delete the card using its ID!
    var boxId = $(".js-multiple-new-cards-btn").data("box-id");
    var cardId = $(el).parent().data("card-id");
    deleteMultipleCards(boxId, [cardId], false);
    $(el).parent().slideUp(function () {
        $(this).remove();
    });
}



function insertCardInMultipleNewCards(el) {
    flashModal(i18n.gettext("Creating card..."));
    var thisCard = $(el).parent();

    var module = "card-core";
    var cardContext = "&cardContext=creating";
    var createMultiple = "&createMultiple=1";
    var boxId = $(".js-multiple-new-cards-btn").data("box-id");

    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + boxId,
        data: "module=" + module + cardContext + createMultiple,
        success: function (data) {
//            try {
            // closeModal();
            thisCard.after(data);

            // newCard(boxId,true,1);

            equalizeCardTopTextHeight(thisCard);

            var nextCard = thisCard.next();
            nextCard.hide();
            nextCard.slideDown(function () {
                var nextCardQuestionText = nextCard.find(".question-text");
                nextCardQuestionText.tinymce(editorConfig(nextCardQuestionText));
                var nextCardAnswerText = nextCard.find(".answer-text");
                nextCardAnswerText.tinymce(editorConfig(nextCardAnswerText));
                nextCard.find(".mc-option-text").each(function () {
                    $(this).tinymce(editorConfig($(this)));
                });

                setTimeout(function () {
                    nextCard.find(".question-text").focus();
                }, 250);
            });

//            } catch (e) {
//                debug('Html parse error');
//            }

            // if ($(".question-text").hasClass('disableCopying')) {
            // enableDocumentSelection(false);
            // }
        }
    }); // end ajax
}


function showCardDiscussion() {
    if ($(".discussion").length > 0) {
        $(".discussion").remove();
    } else {
        // ajax call to load discussion
        $.ajax({
            type: "POST",
            url: "discussion",
            data: cardId,
            success: function (data) {
                // append discussion.php to page container (after layout-control)
            }
        });
    }
}

function showCardInfo() {
    var cardId = $('#cardId').val();
    var html = $(".card-info-content").html();
    $("#general-purpose-modal-content").html(html);
    $("#general-purpose-modal").modal();
}

function checkIfCopyOrMove() {

}

function speak(idText, idLang, alwaysStart, onEndText, onEndLang) {

    if (alwaysStart === undefined) {
        alwaysStart = false;
    }

    var text = $("#" + idText).html();
    text = text.replaceAll('&nbsp;', ' ');
    var language = $("#" + idLang).val();
    debug('Spreche Text: ' + text);
    debug('In Sprache: ' + language);

    var languageCode = language.replaceAll('_', '-');

    if (alwaysStart == false && window.speechSynthesis.speaking) {
        console.error('speechSynthesis.speaking');
        window.speechSynthesis.cancel();
        return;
    }

    var speech = new SpeechSynthesisUtterance(text);

    if (onEndText !== undefined && onEndLang !== undefined) {
        speech.onend = function () {
            speak(onEndText, onEndLang, alwaysStart);
        }
    }

    var voices = window.speechSynthesis.getVoices();
    var languageAvailable = false;
    voices.forEach(function (voice, index) {
        if (voice.lang == languageCode) {
            languageAvailable = true;
        }
    });
    if (languageAvailable) {
        speech.lang = languageCode;
        window.speechSynthesis.speak(speech);
    } else {
        shortInfo(sprintf(i18n.gettext("Text-to-Speech-language %s not available in this browser."), languageCode));
    }

    // responsiveVoice.speak(text, language);
}

function cardRestoreHistoryEntry(cardId, cardHistoryId) {
    flashModal(i18n.gettext("Restoring former state..."));
    $.ajax({
        type: "POST",
        url: "card-history-" + cardId,
        data: "cardRestoreHistoryEntry=" + cardHistoryId,
        success: function (data) {
            flashModal(i18n.gettext("Finished."));
            loadPageByAjax("card-" + cardId);
        }
    });
}

function setStudylevelForCard() {
    var cardId = $('#cardId').val();
    var studyLevel = $('#studyLevelModalInput').val();
    $.ajax({
        type: "POST",
        url: "card-" + cardId,
        data: "setStudyLevel=" + studyLevel,
        success: function (data) {
            var obj = JSON.parse(data.trim());
            shortInfo(obj.message);
            var originalStudyLevel = studyLevel;
            if (studyLevel > 5) {
                studyLevel = 5;
            }
            if (obj.success) {
                $(".bin").removeClass().addClass("bin").addClass("bin-" + studyLevel).addClass("click");
                $(".card-amount-known.study-level").html('<i class="fa fa-calendar"></i><span>' + i18n.gettext("Study level") + ' ' + originalStudyLevel + '</span>').removeClass().addClass("study-level").addClass("show-for-tablet-up-block").addClass("col-" + studyLevel).addClass("card-amount-known").addClass('click');
            }
        }
    });
}

function printCard(cardId) {
    var boxId = $('#boxId').val();
    window.open('export?cardId=' + cardId + '&groupKey=' + boxId + '&e=print' + "&avoidPageBreaks=" + false + "&aboutEachOther=" + true + "&printOption=flexible", '_blank');
    return false;
}

function stripHtml(html) {
    // Create a new div element
    let temporalDivEl = document.createElement("div");
    // Set HTML content using provider
    temporalDivEl.innerHTML = html;
    // Get the text property of the element (browser support)
    return temporalDivEl.textContent || temporalDivEl.innerText || "";
}

function fillNumOfStars(numOfStars) {
    for (i=1; i < 6; i++) {
        if (i <= numOfStars) {
            $(".rating-star.star-" + i).html("&starf;");
        } else {
            $(".rating-star.star-" + i).html("&star;");
        }
    }
}

function highlightStars(el) {
    var numOfStars = $(el).data("starnr");
    fillNumOfStars(numOfStars);
}

function resetStarView(el) {
    var savedNum = $(el).data("savednum");
    fillNumOfStars(savedNum);
}

function saveRating(numOfStars) {
    $(".rating-star-block").data("savednum", numOfStars);
    var cardId = $('#cardId').val();
    $.ajax({
        type: "POST",
        url: "card-" + cardId,
        data: "rating=" + numOfStars,
        success: function (data) {
            debug(data);
        }
    });
}
