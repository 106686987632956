function composeNewNewsBtnClick() {
    $("#newNewsBlock").show();
    $("#newsText").tinymce(editorConfig($("#newsText")));
    $(".js-compose-new-news-btn").hide();
}



function saveNewNewsBtnClick() {
    var newsTitle = encodeURIComponent($('.newsTitle').val());
    var newsText = encodeURIComponent($('.newsText').val());
    var promoted = encodeURIComponent($('#promoted').val());
    var nonce = getNonce();
    flashModal(i18n.gettext('Saving news...'));
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "news",
        data: "newsText=" + newsText + "&newsTitle=" + newsTitle + "&promoted=" + promoted + "&nonce=" + nonce,
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());
            flashModal(obj.answer);
            if (obj.success) {
                refreshPage("news");
            }
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        }
    });
}

function editNews(newsId) {

    $("#newsblock-" + newsId + " .newsTextBlock").tinymce(editorConfig($("#newsblock-" + newsId + " .newsTextBlock")));
    $("#newsblock-" + newsId + " .editButtons").hide();
    $("#newsblock-" + newsId + " #promoted-block").show();
    $("#newsblock-" + newsId + " .saveButtons").show();
    $("#newsblock-" + newsId + " .newsTitleInputBlock").show();
    $("#newsblock-" + newsId + " .module-hl").hide();

}

function deleteNews(newsId) {
    var check = window.confirm(i18n.gettext("Really delete this news entry?"));
    if (check == true) {
        $.ajax({
            type: "GET",
            url: "news",
            data: "deleteNews=" + newsId
        }).done(function (data) {
            var dataArr = JSON.parse(data);
            flashModal(dataArr.answer);
            refreshPage("news");
        });
    }
}

function saveEditedNews(newsId) {
    var newsTitle = encodeURIComponent($("#newsblock-" + newsId + " .newsTitleEdit").val());
    var newsText = encodeURIComponent($("#newsblock-" + newsId + " .newsTextBlock").html());
    var promoted = encodeURIComponent($('#promoted').val());
    $('#promoted-block').hide();
    $.ajax({
        type: "POST",
        url: "news",
        data: "newsId=" + newsId + "&newsTitle=" + newsTitle + "&newsText=" + newsText + "&promoted=" + promoted
    }).done(function (data) {
        var dataArr = JSON.parse(data);
        flashModal(dataArr.answer);
        refreshPage("news");
    });
}