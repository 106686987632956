function loadOsoDoc(el) {
    
    debug("Loading document from Otto Schmidt Online...");
    
    var pubkuerzel = el.attr("PUBKUERZEL");
    var norm = el.attr("NORM");
    
    var behoerde = el.attr("BEHOERDE");
    var datum = el.attr("DATUM");
    var az = encodeURIComponent(el.attr("AZ"));
    
    var docid = el.attr("paragraph_id");
    
    var urlBase = "oso";
    var urlData = "";
    if (typeof pubkuerzel !== 'undefined' && pubkuerzel !== false) {
        urlData = "pubkuerzel=" + pubkuerzel + "&norm=" + norm;
    } else if (typeof behoerde !== 'undefined' && behoerde !== false) {
        urlData = "behoerde=" + behoerde + "&datum=" + datum + "&az=" + az;
    } else if (typeof docid != 'undefined' && docid !== false) {
        urlData = "docid=" + docid;
    }
    
    $("#general-purpose-modal").modal();
    $("#general-purpose-modal .expandModalButton").show();
    
    showAjaxLoader("#general-purpose-modal-content");
    
    $.ajax({
        type: "GET",
        url: urlBase,
        data: urlData
    }).done(function (data) {
        debug("OSO output: " + data);
        let content = '<div class="oso-content">' + data + '</div>';
        $("#general-purpose-modal-content").html(content);
        $("reference").on("click", function() {
            loadOsoDoc($(this));
        });
    }).fail(function (xhr, status) {
        alert(i18n.gettext("Error while accessing Otto Schmidt Online."));
    }).complete(function (xhr, status) {
        // $("body").css("cursor", "default");
    });

    return false;
    
}